html, body {
    margin: 0;
    height: 100vh; 
  }
  @media all and (display-mode: standalone) {
    body {
      background-color: yellow !important;
    }
  }  

.TopBar {
    box-sizing: border-box;
    align-items: center;
    min-height: 47px;
    padding: 0px 32px;
   // box-shadow: rgb(0 0 0 / 13%) 0px 6.4px 14.4px 0px, rgb(0 0 0 / 11%) 0px 1.2px 3.6px 0px;
   .topLogo>a>img{
       max-height: 32px;
       height: 32px;
   }
   .signInText{
       padding-right: 5px;
   }
}

@media screen and (max-width: 479px)  {
    .TopBar {
        padding: 0px 16px;
    }
}
.darkModeToggle{
    padding-top:9px
}

.Content {
    flex: 1;
    align-items:stretch;
    align-content:flex-start;
    flex-direction: row;
    overflow: hidden;
}
.NavPanel{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    .NavSiceToggle{
        
        bottom: 0;
        right: 0;
        text-align: end;
    }
}
.WrapContent{
    display: flex;
    height: 100%;
    flex: 1; 
    overflow: hidden; 
    flex-direction: column;
}
.MainContent{

    flex: 1; /* takes the remaining height of the "container" div */
    overflow: auto; /* to scroll just the "main" div */
    height: 100%;
}


.FooterBar {
    box-sizing: border-box;
     align-items: center;
    min-height: 47px;
    padding: 0px 32px;
    .footerButton{
        outline: transparent;
        position: relative;
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        box-sizing: border-box;
        border: 1px solid transparent;
        display: inline-block;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        padding: 0px 4px;
        border-radius: 2px;
       
        background-color: transparent;
        user-select: none;
    }
}
@media screen and (max-width: 319px)  {
    .hideExtraSmale {
        display:none
    }
    .showExtraSmale {
        display:inline-block;
    }
}
.waffelbtn{
    margin-left:12px;
    margin-top: 5px;
    &>button>span>i{
        font-size: 32px;
        line-height: 32px;
        height: 32px;
       
    }
}

@media screen and (min-width: 320px)  {
    .hideExtraSmale {
        display:inline-block
    }
    .showExtraSmale {
        display:none;
    }
}

