.mainTelemetry{
    flex: 1;
    align-items:center;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    .telemetryIcon,
    .telemetryValues{
        display: flex;
    }
    .telemetryIcon{
    
        img {
            height: 3em;
            max-width: 100%;
        }
        .noimg{
            height: 3em;
        }
    }
}

.telemetry{
    padding:0.5em;
   // margin-bottom: 20px;
    display: flex;
    flex-direction: row;
   
    .telemetryIcon{
       
        width: 1.7em;
        text-align: left;
        padding-left: 0.3em;
        
       
        img {
            height: 1.5em;
            max-height: 100%;
            max-width: 100%;
        }
        .noimg{
            height: 1.5em;
        }
    }
}

.telemetry,
.mainTelemetry{
   
    background-color: RGB(255 255 255 / 25%);
    //box-shadow: 0 13px 26px 0 RGB(0 0 0/22%), 0 2px 7px 0 RGB(0 0 0/18%);
    &.dark{
        background-color: RGB(0 0 0 / 18%);
    }
    .telemetryValues{
.telemetryValue{
    padding-right: 0.4em ;
}
    }
   
}

@media only screen 
 
  and (max-device-width: 1024px)
  and (min-device-width: 320px) 
  and (max-device-height: 400px)
  and (min-device-height: 280px) 

  and (orientation: landscape) {
    .mainTelemetry{
        padding:1em;
        flex-direction: row;
        .telemetryIcon{
padding-right: 15px;
        }
    }
    .telemetry{
        width: 6em;
        flex-direction: column;
        padding:1em;
        margin-bottom: 0.5em;
        .telemetryIcon{
            width: auto;
            text-align: center;
        }
    }

  }

  @media only screen 
 
  and (max-device-width: 1024px)
  and (min-device-width: 320px) 
  and (max-device-height: 400px)
  and (min-device-height: 280px) 

  and (orientation: landscape) {
    

 .telemetryView{
        display: flex;
        height: 100%;
        flex-direction: row;
        .mainLeftTelemetry{
            margin:0;
            margin-bottom: 0.5em;
            justify-content: center;
            
            
            flex-grow: 2;
        }
        .secondaryTelemetry{
            justify-content: space-evenly;
            flex-direction: row;
            flex-grow: 2;
          
        }
    }

    
}

@media only screen 

 and (max-device-width: 600px)
  and (min-device-width: 200px) 
  and (max-device-height: 700px)
  and (min-device-height: 570px) 

  and (orientation:portrait)  {
  
        .mainTelemetry{
            padding:1em;
        }
       
    .telemetry{
                
        flex-direction: column;
        padding:1em;
        margin-bottom: 0.5em;
        .telemetryIcon{
            width: auto;
            text-align: center;
        }
    }

}