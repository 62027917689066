.textblock{
  width: 100%;
    textarea {
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: inherit;
    display: block;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
min-height: 40px;
    line-height: inherit;
    margin: 0;
    outline: 0;
    min-height:100%;
    text-align: inherit;
    white-space: pre;
    width: 100%;

    &::placeholder,
    &::-webkit-input-placeholder {
      color: inherit !important;
      font-weight: 600;
    }
}
}