
.page{
    padding:0px 15px;
    .header{
        padding: 8px 10px 15px 10px;
        textarea {
            background-color: transparent;
            border: none;
            box-sizing: border-box;
            color: inherit;
            display: block;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
          height: 5rem;
            line-height: inherit;
            margin: 0;
            outline: 0;
            overflow: hidden;
            resize: none;
            text-align: inherit;
            white-space: pre;
            width: 100%;
        
            &::placeholder,
            &::-webkit-input-placeholder {
              color: inherit !important;
              font-weight: 600;
            }
    }
    }
    .headerInfo{
        h1{
        font-size: 4rem;
        font-weight: 600;
        }
        .editinfo{
            font-size: 1em;
        font-weight: 600;
        
        padding:5px 5px;
        
        margin-left: -8px;
        flex-direction: row;
        display: flex;
        flex-flow:wrap;
        >div{
            padding:10px 15px;
            flex-grow: 1;
            width: 180px;
        }
        .headerdate,
        .headeruser{
        
           display: block;
           white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        }
    }
    .headerExtraHeight{
        min-height: 250px !important;
    }
   .headerimage {
       display: block;
      background-repeat:  no-repeat;
      background-size: cover;
      background-position:50% 50%;
      min-height: 200px;
      position: relative;
      margin-top: 0px;
      margin-left: -25px;
      margin-right: -25px;
      margin-bottom: 0px;
      
      .headerInfo{
        position:absolute;
        bottom: 10px;
        left: 25px;
        color: #fff !important;
        
    }
   }
    .loading{
        vertical-align: center;
    }
.error{
    color: red;
}
.nodata{
    vertical-align: center;
}
.pageActionAddSection{
 text-align: center;
}
.sectionWrapper{
    flex-direction: row;
    display: flex;
    flex-basis: 100%;
    width: 100%;
    flex-wrap: wrap;
    .mainSections{
        flex-grow:4;
        flex-direction:column;
        width: 80%;
        
    }
    .rightSection{
        flex-grow:2;
        flex-direction:column;
        max-width: 300px;
        min-width:250px;
        width: 20%;
    }
   
}

.section{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.twoColumnRight,
.twoColumnLeft{
    flex-grow:2;
}
.twoColumn,
.twoColumnRight,
.twoColumnLeft,
.threeColumn,
.oneColumn{
    flex-grow:1;
}
.col{
    display: flex;
    flex-direction:column;
    flex-basis: 100%;
  flex: 1;
  padding: 0px 5px;
}
.blocks
{
    padding: 0px 0px 15px 00px;
    display: flex;
    flex-direction:column;
    .block{
        display: flex;
    flex-direction:column;
    padding: 0px 10px 15px 10px;
    .blockHeadline{
        display: flex;
    }
    .blockContent{
        display: flex;
    }
    }
}


}

.commandBar{
    position: sticky;
    top: 0;
    z-index: 9999;
  //  margin:-15px -15px 0px -15px;
 
    
    box-shadow: RGB(0 0 0 / 13%) 0px 6.4px 14.4px 0px, RGB(0 0 0 / 11%) 0px 1.2px 3.6px 0px;
}

.editMode{
    .editBlockWrapper,
    .editSectionWrapper{
        min-height: 100px;
    }
    .rightSection:hover,
    .editSectionWrapper:hover{
        .section{
        //border-bottom: 1px dashed black;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='1' stroke-dasharray='11%2c 16%2c 9%2c 16' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
   
        background-repeat:no-repeat ;
        }
        
    }
/*
    .col:hover{
        background-color: lightpink;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='10' stroke-dasharray='2%2c 6' stroke-dashoffset='26' stroke-linecap='butt'/%3e%3c/svg%3e");
        background-repeat:no-repeat;
    }
    */
    .block:hover{
        background-color: lightskyblue;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='10' stroke-dasharray='2%2c 6' stroke-dashoffset='26' stroke-linecap='butt'/%3e%3c/svg%3e");
        background-repeat:no-repeat;
    }
    .editSectionWrapper{
        position: relative;
        padding:30px;
        margin:0px -15px;
       
                }
    }
.editBlockWrapper{
    position: relative;
    padding:30px;
    &:hover{
            
        .actionBefore{
            display: block;
        }
        .actionAfter{
            display: block;
        }
        .actionLayer{
            display: flex;;

    }
            }
   
}
   .editBlockWrapper,
    .editSectionWrapper{
       
        .actionBefore,
        .actionAfter{
            display: none;
            position: absolute;
            left:50%;
            right:50%;
        }
        .actionBefore {
            top: 0px;
        }
        .actionAfter{
            bottom:0px;
        }
        .actionLayer{
            position: absolute;
            display: none;
            left: 0px;
            
            flex-direction: column;

    }
    &:hover{
            
        &>div.actionBefore{
            display: block;
        }
        &>div.actionAfter{
            display: block;
        }
        &>div.actionLayer{
            display: flex;;

    }
        
    }
} 
.displayMode{
    content: "";
}

.blockHeadline{
    textarea {
        background-color: transparent;
        border: none;
        box-sizing: border-box;
        color: inherit;
        display: block;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        height: 40px;
        line-height: inherit;
        margin: 0;
        outline: 0;
        overflow: hidden;
        resize: none;
        text-align: inherit;
        white-space: pre;
        width: 100%;
    
        &::placeholder,
        &::-webkit-input-placeholder {
          color: inherit !important;
          font-weight: 600;
        }
}
}