.deviceInfo{
    text-align: center;
    padding:2% 5% 4% 5%;
    box-shadow: 0 25.6px 57.6px 0 RGB(0 0 0 / 22%), 0 4.8px 14.4px 0 RGB(0 0 0 / 18%);
    @media screen and (max-width: 479px) and (min-width: 320px) {
        font-size: 1em;
    }
    @media screen and (max-width: 639px) and (min-width: 480px) {
        font-size: 2em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (min-height:760px) {
        font-size: 2.8em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (max-height:759px) {
        font-size: 2.5em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (max-height:670px) {
        font-size: 2em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (max-height:570px) {
        font-size: 1.5em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (max-height:490px) {
        font-size: 1em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) and (max-height:390px) {
        font-size: 1em;
    }

    @media screen and (max-width: 1365px) and (min-width: 1024px) and (min-height:938px) {
        font-size: 3.5em;
    }
    @media screen and (max-width: 1365px) and (min-width: 1024px) and (max-height:937px) {
        font-size: 3em;
    }
    @media screen and (max-width: 1365px) and (min-width: 1024px) and (max-height:820px) {
        font-size: 2.5em;
    }
    @media screen and (max-width: 1365px) and (min-width: 1024px) and (max-height:730px) {
        font-size: 2em;
    }

   
    @media screen and (max-width: 1919px) and (min-width: 1366px) and (min-height:1024px) {
        font-size: 4em;
    }
    @media screen and (max-width: 1919px) and (min-width: 1366px) and (max-height:1023px) {
        font-size: 3em;
    }
    @media screen and (max-width: 1919px) and (min-width: 1366px) and (max-height:848px) {
        font-size: 2.5em;
    }
    @media screen and  (max-width: 1919px) and (min-width: 1366px) and (max-height:720px) {
        font-size: 2em;
    }
    @media screen and  (max-width: 1919px) and (min-width: 1366px) and (max-height:640px) {
        font-size: 2em;
    }

    @media screen and  (min-width: 1920px) and (min-height:1064px) {
        font-size: 4em;
    }
    @media screen and  (min-width: 1920px) and (max-height:1063px) {
        font-size: 3em;
    }
    @media screen and  (min-width: 1920px) and (max-height:848px) {
        font-size: 2.5em;
    }
    @media screen and  (min-width: 1920px) and (max-height:720px) {
        font-size: 2em;
    }
    @media screen and  (min-width: 1920px) and (max-height:640px) {
        font-size: 2em;
    }
/*iPad in landscape*/
  /*  @media only screen 
    and (min-device-width : 768px) 
    and (max-device-width : 1024px) 
    and (orientation : landscape) { 
        font-size: 2.8em;
    
    }*/

    .headline{
        &>img{
            height: 1.2em;
        }
        .text{
            padding-left:3%;
        font-size: 150%;
        }
    }
    .body{
        .values{
        font-weight: 700;
        font-size: 120%;

        }
        .timespan{
            font-weight: 300;
        }
        .sensore{
            font-weight: 300;
            &>i{
                padding-left:1%;
            }
        }
    }
    .footer {
        .lastUpdate{
            font-weight: 300;
        }
    }
}
.callout{
    padding: 1.8% 2.5%;
   
}
.callouttext{
    @media screen and (max-width: 479px) and (min-width: 320px) {
        font-size: 1.2em;
    }
    @media screen and (max-width: 639px) and (min-width: 480px) {
        font-size: 2em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) {
        font-size: 2.8em;
    }
    @media screen and (max-width: 1365px) and (min-width: 1024px) {
        font-size: 3.5em;
    }
    @media screen and (max-width: 1919px) and (min-width: 1366px) {
        font-size: 4em;
    }
    @media screen and  (min-width: 1920px) {
        font-size: 4em;
    }
}



/*iPhone in landscape*/
/* Landscape */
/*
@media only screen and (max-width: 1024px) and (min-width: 280px) and
    (max-height: 300px) and (min-height: 200px) 
    and (orientation: landscape)
*/
@media only screen 
 
  and (max-device-width: 1024px)
  and (min-device-width: 320px) 
  and (max-device-height: 400px)
  and (min-device-height: 280px) 

  and (orientation: landscape) {
    .deviceInfo{ font-size: 0.8em; 
}
    
}

  
   
.arrow_down {
    position: absolute;  
    top: -0.1em;
    left:-0.25em;
    margin: auto;
    height: 0; 
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    
    border-top:0.3em solid black;
  }
  .arrow_up {
    position: absolute;  
    bottom: -0.1em;
    left:-0.25em;
    width: 0; 
    height: 0; 
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    
    border-bottom: 0.3em solid black;
  }
  
  
  .arrow-right {
    width: 0; 
    height: 0; 
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    
    border-left: 60px solid green;
  }
  
  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; 
    
    border-right:10px solid blue; 
  }

  .footerinforow{
            display: flex;
            flex-direction: row;
            justify-content:space-between;
      .footerinfoleft{
        display: flex;
      }
      .footerinforight{
        display: flex;
        align-items:baseline;
       
      }
      .footerinfocenter{
        display: flex;
        flex-direction: column;
      }
  }


  
  .footerinforight img{
    @media screen and (max-width: 479px)  {
        max-height: 2.2em;
    }
    @media screen and (max-width: 639px) and (min-width: 480px) {
        max-height: 2.4em;
    }
    @media screen and (max-width: 1023px) and (min-width: 640px) {
        max-height: 2.5em;
    }
    @media screen and (max-width: 1365px) and (min-width: 1024px) {
        max-height: 2.5em;
    }
    @media screen and (max-width: 1919px) and (min-width: 1366px) {
        max-height: 2.7em;
    }
    @media screen and  (min-width: 1920px) {
        max-height: 2.7em;
    }
}