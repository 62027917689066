
.wrapper_404{
    position: relative;
    text-align: center;
}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    //mix-blend-mode:multiply;

    h1{
        font-size:80px;
        color:black;
        }
        
            
        
 }
 
 
 

			 .link_404{			 
	padding: 10px 20px;

    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ 
        position: absolute;
        width: 100%;
        top:370px;
        z-index: 9999;
        h2{
            font-size:30px;
            color:black;
            }
         }

    .textcenter{
        text-align: center;
    }