
.telemetryView{
   
    align-items:stretch;
    
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    .genericTelemetry{
        flex:1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       flex-wrap: wrap;
       &>div {
        margin: 0.4em 0.5em;
        padding: 0.5em 0.2em;
       }
       
    }
    
    
  
   
}
/*iPhone in landscape*/
/* Landscape */
/*
@media only screen and (max-width: 1024px) and (min-width: 280px) and
    (max-height: 300px) and (min-height: 200px) 
    and (orientation: landscape)
*/
@media only screen 
 
  and (max-device-width: 1024px)
  and (min-device-width: 320px) 
  and (max-device-height: 400px)
  and (min-device-height: 280px) 

  and (orientation: landscape) {
    

 .telemetryView{
        .genericTelemetry{
            justify-content: space-evenly;
            flex-direction: column;
            flex-grow: 2;
           
        }
        
    }

    
}

@media only screen 

 and (max-device-width: 600px)
  and (min-device-width: 200px) 
  and (max-device-height: 700px)
  and (min-device-height: 570px) 

  and (orientation:portrait)  {
    .telemetryView{
        
        .genericTelemetry{
            justify-content: center;
            justify-content: space-evenly;
            flex-direction: column;
            flex-grow: 2;
            
        }
    }
}
