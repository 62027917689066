
.telemetryView{
    display: flex;
    align-items:stretch;
    
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: 6em;
    .mainLeftTelemetry{
        flex: 1;
        margin-right: 0.6em;
       
        
        
        flex-grow: 8;
        height: 100%;
    }
    .secondaryTelemetry{
        flex: 1;
        flex-grow: 2;
        justify-content:space-between;
        flex-direction: column;
        display: flex;
        
       
    }
    
  
   
}
/*iPhone in landscape*/
/* Landscape */
/*
@media only screen and (max-width: 1024px) and (min-width: 280px) and
    (max-height: 300px) and (min-height: 200px) 
    and (orientation: landscape)
*/
@media only screen 
 
  and (max-device-width: 1024px)
  and (min-device-width: 320px) 
  and (max-device-height: 400px)
  and (min-device-height: 280px) 

  and (orientation: landscape) {
    

 .telemetryView{
        display: flex;
        height: 100%;
        flex-direction: row;
        .mainLeftTelemetry{
            margin:0;
            margin-bottom: 0.5em;
            justify-content: center;
            
            
            flex-grow: 2;
        }
        .secondaryTelemetry{
            justify-content: space-evenly;
            flex-direction: row;
            flex-grow: 2;
          
        }
    }

    
}

@media only screen 

 and (max-device-width: 600px)
  and (min-device-width: 200px) 
  and (max-device-height: 700px)
  and (min-device-height: 570px) 

  and (orientation:portrait)  {
    .telemetryView{
        display: flex;
        height: 100%;
        flex-direction: column;
        .mainLeftTelemetry{
            margin:0;
            margin-bottom: 0.5em;
           
        }
        .secondaryTelemetry{
            justify-content: center;
            
        }
    }
}
